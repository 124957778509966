@import "variable";
body {
  .settingsWrapper {
    // background: linear-gradient(180deg, #f7fbf9 0%, #ecf3f8 100%);
    // padding: 114px 24px 40px 264px;
    padding: 114px 24px 40px 264px;
    .settingsHeading {
      margin: 0 -24px 24px -24px;
      .settingsTitle {
        color: $gray100;
        padding: 16px 24px;
      }
      border-bottom: 1px solid $gray10;
    }
    .addWorkspaceTitleWrapper {
      background-color: rgba($gray10, 0.2);
      border-bottom: 1px solid $gray10;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      .addWorkspaceTitle {
        display: flex;
        align-items: center;
        .addWorkspaceHeader {
          color: $gray100;
          padding-left: 8px;
        }
      }
      .closeDialog {
        cursor: pointer;
      }
    }
    .settingsInnerBlk {
      //margin: 0 24px;
      background: $white;
      //margin-bottom: 40px;
      box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
      border-radius: 6px;
      flex-grow: 1;
      // height: 100vh;
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      .settingsInnerLeftSidebar {
        padding: 16px;
        width: 252px;
        border-right: 1px solid $gray10;
        .filterItem {
          padding: 16px;
          color: $gray50;
          font-family: $font_Inter;
          font-size: $font14;
          font-weight: $mediumFont;
          text-transform: capitalize;
          & span {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
          }
          cursor: pointer;
        }
        & :global(.Mui-selected) {
          background-color: rgba(67, 97, 238, 0.11);
          border-radius: 6px;
          color: $gray90;
        }
        & :global(.MuiTabs-indicator) {
          display: none;
        }
      }
      .setWallpaperWrapper {
        width: 740px;
        .addWallpaperContentWrapper {
          width: 100%;
          .addWallpaperFrameWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .addWallpaperFrameImage {
              position: relative;
              .frameUpdatedPicture {
                position: absolute;
                object-fit: cover;
                left: 5px;
                top: 5px;
                width: 95%;
                height: 83%;
              }
            }
          }
          .addWallpaperConfigWrapper {
            background: url(https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/assets/img/wallpaper_config_bg.png);
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 0 8px 8px 0;
            margin-left: 70px;

            padding: 34px 98px;
            .ConfigTitle {
              color: $gray3;
            }
            .ConfigSubTitle {
              padding: 4px 0 16px;
              font-weight: $regularFont;
              color: $gray50;
            }
            .importButtonWrapper {
              position: relative;
              display: inline-block;
              .modal {
                display: none; /* Hidden by default */
                position: fixed; /* Stay in place */
                z-index: 999999999; /* Sit on top */
                left: 0;
                top: 0;
                width: 100%; /* Full width */
                height: 100%; /* Full height */
                overflow: hidden; /* Enable scroll if needed */
                background-color: rgb(0,0,0); /* Fallback color */
                background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
                align-items: center;
                justify-content: center;
              }
              
              /* Modal Content */
              .modalContent {
                background-color: #fefefe;
                margin: auto;
                padding: 20px;
                border: 1px solid #888;
                width: 50%;
                // margin-top: 250px;
              }
              
              /* The Close Button */
              .close {
                color: #aaaaaa;
                float: right;
                font-size: 28px;
                font-weight: bold;
              }
              
              .close:hover,
              .close:focus {
                color: #000;
                text-decoration: none;
                cursor: pointer;
              }
              .importButton {
                padding: 9px 20px;
              }
              .inputFile {
                position: absolute;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
              }
            }
          }
          .wallpaperCounts {
            padding: 72px 0 16px;
            color: $gray3;
          }
          .appLinkWallpaper{
            background: #f5f9f8;
            display: flex;
            align-items: center;
            border-radius: 8px;
            padding: 5px;
            .appLinkIconContainer{
              display: flex;
              gap: 10px;
            }
            .appLinkText{
              text-overflow: ellipsis;
              overflow: hidden;
              color: blue;
              display: block;
            }
          }
          .wallpapersList {
            position: relative;
            object-fit: cover;
            width: 200px;
            height: 100px;
            cursor: pointer;
            margin-top: 20px;
            .wallpapers {
              max-width: 200px;
              max-height: 200px;
              padding: 6px;
              cursor: pointer;
              border: 1px solid $gray10;
              border-radius: 8px;
              &.active {
                border: 2px solid $blue;
              }
            }
            .defaultBtn {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%);
              background-color: $white;
              padding: 4px 8px;
              border-radius: 4px;
              border: 1px solid $blue;
              font-size: $font14;
              line-height: $lineHeight21;
              color: $blue;
              width: 70%;
            }
            .currentBtn {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%);
              background-color: $white;
              padding: 4px 10px;
              border-radius: 4px;
              border: 1px solid $blue;
              font-size: $font14;
              line-height: $lineHeight21;
              color: $blue;
              width: 140px;
              cursor: default;
            }
            .deleteBtn {
              position: absolute;
              top: 18px;
              right: 14px;
              cursor: pointer;
            }
          }
        }
      }
      .welcomeDisplayWrapper {
        width: 740px;
        .addWallpaperFrameWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .addWallpaperFrameImage {
            position: relative;
            .frameUpdatedPicture {
              position: absolute;
              object-fit: cover;
              left: 5px;
              top: 5px;
              width: 95%;
              height: 83%;
            }
            .imageOverlay {
              position: absolute;
              padding: 6px;
              top: 0;
              left: 0;
              //background-color: rgba($gray100, 0.3);
              .overlayTitle {
                color: $white;
                font-weight: $mediumFont;
                padding-bottom: 4px;
              }
              .overlaySubTitle {
                color: $white;
                font-size: 8px;
                line-height: 11px;
              }
            }
          }
        }
        .addWallpaperConfigWrapper {
          background: url(https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/assets/img/wallpaper_config_bg.png);
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 0 8px 8px 0;
          padding: 24px 48px;
          .ConfigTitle {
            color: $gray3;
          }
          .ConfigSubTitle {
            padding: 4px 0 16px;
            font-weight: $regularFont;
            color: $gray50;
          }
          .importButtonWrapper {
            position: relative;
            display: inline-block;
            .importButton {
              padding: 9px 20px;
            }
            .inputFile {
              position: absolute;
              left: 0;
              width: 100%;
              height: 22%;
              opacity: 0;
              cursor: pointer;
            }
          }
        }
        .displayUpdateWrapper {
          padding: 72px 0 0;
          .displayUpdateImage {
            .displayUpdateTitle {
              padding-bottom: 6px;
            }
            .displayUpdateButton {
              display: flex;
              align-items: center;
              margin-bottom: 32px;
              .importButtonWrapper {
                position: relative;
                display: inline-block;
                .importButton {
                  padding: 5px 16px;
                  margin-left: 16px;
                }
                .inputFile {
                  position: absolute;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  cursor: pointer;
                }
              }
            }
          }
          .displayTitleUpdateSection {
            .displayBodyInput {
              & label {
                color: $gray70;
                font-weight: $mediumFont;
              }
              & input {
                &::placeholder {
                  color: $gray100;
                  word-break: break-word;
                }
              }
            }
          }
          .displayBodyUpdateSection {
            position: relative;
            margin-bottom: 32px;
            .displayBodyInput {
              & label {
                color: $gray70;
                font-weight: $mediumFont;
              }
              & input {
                &::placeholder {
                  color: $gray100;
                  word-break: break-word;
                }
              }
            }
            .displayBodyInputCounter {
              position: absolute;
              right: 0;
              top: 0;
            }
          }
          .displayButtonUpdateSection {
            text-align: left;
            .updateBtn {
              padding: 14px 72px;
            }
          }
        }
      }
      .domainsWrapper {
        width: 740px;
        .addDomainWrapper {
          margin-bottom: 48px;
          .addDomainSection {
            .addDomainInput {
            }
          }
          .customizationTitle{
            display: grid;
            place-items: center;
            color: #011627;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 40px;
          }
          .subDomainSection {
            margin-top: 20px;
            display: grid;
            grid-template-columns: 1fr 20%;
            .subDomainInput {
            }
            .subDomainText{
              // justify-self: center;
              margin-left: 4px;
              align-self: center;
            }
          }
          .saveSubDomainBtnWrapper {
            margin-top: 55px;
            text-align: center;
            .addDomainBtn {
              padding: 10px 20px;
            }
          }
          .addDomainBtnWrapper {
            text-align: right;
            .addDomainBtn {
              padding: 10px 20px;
            }
          }
        }
        .domainAddedListWrapper {
          .domainAddedTitleWrapper {
            display: flex;
            align-items: center;
            text-align: center;
            .domainAddedTitle {
              font-weight: $mediumFont;
              color: $gray50;
              margin-bottom: 24px;
              display: inline-block;
              position: relative;
            }
            .domainAddedTitle::after {
              content: " ";
              display: block;
              position: absolute;
              top: 50%;
              left: -120px;
              width: 540px; /* 100px line on either side */
              border-bottom: 1px solid $gray10;
              left: auto;
              right: -560px;
            }
          }
          .addedDomainListItem {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px;
            border-bottom: 1px solid $gray10;
            .addedDomainListItemLeftBlk {
              display: flex;
              align-items: center;
              .addedDomainName {
                color: $gray100;
                padding-left: 16px;
              }
            }
            .addedDomainListItemRightBlk {
              cursor: pointer;
            }
          }
        }
      }
      .tabPanel {
        width:100%;
      }
    }
  }
}
